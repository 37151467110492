<template>
<div class="locale-changer text-end">
  <v-tooltip
    v-for="locale in locales"
    :key="locale.language"
    bottom
    color="grey darken-3"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="changeLocale(locale.language)"
        v-bind="attrs"
        v-on="on"
        fab
        small
        tabindex="-1"
        :color="
          $i18n.locale == locale.language
            ? 'grey lighten-2'
            : 'grey lighten-5'
        "
        :elevation="$i18n.locale == locale.language ? '0' : '2'"
        class="mr-1 mb-1"
      >
        <span :class="'fi fi-' + locale.country"></span>
      </v-btn>
    </template>
    <span>{{ locale.name }}</span>
  </v-tooltip>
</div>
</template>

<style lang="scss">
@import "~flag-icons/css/flag-icons.min.css";
</style>

<script>
import API from '@/services/API.js'

export default {
  name: 'LocaleChanger',
  data: () => ({
    locales: [
      { language: 'sv', country: 'se', name: 'Svenska' },
      { language: 'en', country: 'gb', name: 'English' }
    ]
  }),
  methods: {
    changeLocale: function (lang) {
      this.$i18n.locale = lang
      API.setLanguage(lang)
      window.dayjs.locale(lang)
      window.localStorage.setItem('user_language', lang)

      // Aktivt val att ladda om, tänker att man sällan byter språk och oftast på indexsidan
      // Om vi har stöd för många språk känns det bättre med en omladdning vid språkbyte än att skicka med alla varianter alltid
      this.$store.dispatch('checkin/initialise', { force: true })
    }
  }
}
</script>
