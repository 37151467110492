const toHumanReadableAmount = function (amount, minimumFractionDigits = 'auto') {
  // andra parametern kan sättas som en siffra som "minsta antal ören" att visa
  if (minimumFractionDigits === 'auto') {
    const hasDecimals = amount !== Math.round(amount)
    if (hasDecimals) {
      minimumFractionDigits = 2 // gör så visar två decimaler även om det hade "räckt" med en - för att "1,50 kr" ser bättre ut än "1,5 kr"
    } else {
      minimumFractionDigits = 0
    }
  }
  const formatter = new Intl.NumberFormat('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits
  })
  return formatter.format(amount)
}

export default {
  toHumanReadableAmount
}
