<template>
<div class="terms-dialog">
  <v-dialog
    v-model="show"
    scrollable
    max-width="900"
  >
    <v-card>
    <v-toolbar
      flat
      color="primary"
      dark
    >
      <v-toolbar-title>Villkor</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        dark
        @click="show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-tabs vertical>
      <v-tab class="justify-md-start">
        <v-icon left>
          mdi-shield-account-outline
        </v-icon>
        <span class="d-none d-md-block">Sekretesspolicy</span>
      </v-tab>
      <v-tab class="justify-md-start">
        <v-icon left>
          mdi-cookie
        </v-icon>
        <span class="d-none d-md-block">Cookie Policy</span>
      </v-tab>

      <v-tab-item style="height: 450px; max-height: 450px; overflow-y: scroll;">
        <v-card flat>
          <v-card-text class="">
            <h1 class="pb-2">Sekretesspolicy</h1>
            <h2 class="pb-1 text-subtitle-2">Senaste uppdatering: 13 dec 2022</h2>
            <p>Sekretesspolicyn beskriver hur din data kommer att behandlas av CampingOnline Sverige AB (559203-1107). Policyn innehåller information om hantering och lagring av dina uppgifter, vilka leverantörer som bearbetar din data och dina rättigheter i enlighet med GDPR. Denna policy gäller för personuppgifter insamlade via någon av våra plattformar som just nu inkluderar campingarnas bokningssystem, campingarnas hemsidor och våra hemsidor.</p>
            <h2 class="text-subtitle-1">Vilken information samlas in</h2>
            <p>Uppgifterna som förekommer i vår databehandling är kunduppgifter (namn, adress), kontaktuppgifter (e-post, telefonnummer), bokningsinformation, köpinformation (inköp som är direkt kopplade till din bokning) och betalningsinformation (form, tidpunkt och betalt belopp).</p>
            <h2 class="text-subtitle-1">Var samlas informationen in</h2>
            <p>Den information vi lagrar är den som kunden själv har angivit. Personuppgifter lagras i samband med att ett konto eller en bokning skapas på hemsidan. Motsvarande uppgifter kan också samlas in via andra försäljningskanaler som telefon, e-post eller vid bokning direkt på plats.</p>
            <h2 class="text-subtitle-1">För vilket syfte behövs informationen</h2>
            <p>CampingOnline Sverige AB samlar in uppgifter för att kunna administrera tillhandahållna tjänster och kommer att förmedla till samt lagra uppgifterna åt den eller de campingar som du är kund hos.</p>
            <p>Syfte: Erbjuda kontotjänster</p>
            <p>Rättslig grund: Berättigat intresse</p>
            <p>Lagringsperiod: Så länge du väljer att behålla ett konto hos oss kommer vi att lagra grundläggande kund och kontaktuppgifter. Väljer du att ta bort ditt konto kommer vi radera dina uppgifter men dessa kan ändå finnas kvar för respektive campingen om det finns rättslig grund för det.</p>
            <p>Syfte: Hantera bokningar</p>
            <p>Rättslig grund: Avtal</p>
            <p>Lagringsperiod: Så länge uppgifter behövs för att kunna fullfölja avtalet för båda parter, därefter är det möjligt att själv begära radering av uppgifterna.</p>
            <p>Syfte: Fakturering och bokföring</p>
            <p>Rättslig grund: Rättslig förpliktelse</p>
            <p>Lagringsperiod: Minst under den period som krävs enligt bokföringslagen, därefter kan du begära att vi anonymiserar dina uppgifter.</p>
            <p>Syfte: Myndighetskrav</p>
            <p>Rättslig grund: Rättslig förpliktelse</p>
            <p>Lagringsperiod: Under den tid som behövs för att tillgodose krav från myndigheter så som Polisen och Skatteverket men även för statistik som krävs av SCB.</p>
            <p>Syfte: Information och nyhetsbrev</p>
            <p>Rättslig grund: Samtycke</p>
            <p>Lagringsperiod: Tillsvidare så länge samtycket gäller.</p>
            <h2 class="text-subtitle-1">Leverantörer</h2>
            <p>I enlighet med GDPR är CampingOnline Sverige AB personuppgiftsansvarig för de uppgifter som registreras hos oss. För att kunna tillhandahålla våra tjänster behöver vi överföra en del uppgifter till en del underleverantörer som i detta fall utgör personuppgiftsbiträden. Leverantörerna behandlar uppgifterna i enlighet med ett personuppgiftsbiträdesavtal. All lagring och databehandling sker inom EU/EES. Bokningsuppgifter och inköp samt bokföringsdata lagras exklusivt på svenska servrar.</p>
            <h2 class="text-subtitle-1">Följande leverantörer har tillgång till delar av uppgifterna:</h2>
            <p>Google Commerce Limited</p>
            <p>Lagring av kund och kontaktuppgifter. Används primärt för inloggning och kontohantering. Uppgifterna lagras på flera platser inom EU för att ge bästa möjliga kundupplevelse. I dagsläget finns servrarna i Belgien och Nederländerna.</p>
            <p>Amazon Web Services EMEA SARL </p>
            <p>Lagring av bokningar, köp, e-post, sms och bokföringsdata. Uppgifterna lagras på tre datacenter inom Sverige.</p>
            <p>The Constant Company, LLC (Vultr) </p>
            <p>Bokningssystem och hemsidor. Uppgifterna lagras på Equinix SK3 i Spånga, Stockholm.</p>
            <p>Stripe Payments Europe Ltd</p>
            <p>Som en finansiell institution är Stripe tvungna att överföra data mellan olika länder och banker för att genomföra och bekräfta transaktioner. Stripe följer bankernas strikta krav på säkerhet och har i sitt avtal med både slutkund och oss reglerat hur deras data hanteras. Stripe får informationen direkt av slutkund vid kortbetalning, den datan förmedlas alltså inte av CampingOnline Sverige AB. Vi sparar endast information om vilket belopp som är betalt och den tidpunkt som betalningen genomfördes.</p>
            <h2 class="text-subtitle-1">Dina rättigheter</h2>
            <p>Du har följande rättigheter enligt GDPR:</p>
            <ul>
              <li>Rätt till information</li>
              <li>Rätt till tillgång</li>
              <li>Rätt till rättelse</li>
              <li>Rätt till radering</li>
              <li>Rätt till begränsning av behandling</li>
              <li>Rätt att göra invändningar</li>
              <li>Rätt till dataportabilitet</li>
            </ul>
            <p>Vi gör allt vi kan för att säkerställa att dina uppgifter är korrekta och tillgängliga för dig. Om du har ett konto hos CampingOnline har du möjlighet att själv se och rätta dina uppgifter. Merparten av den databehandling som görs är nödvändig för att kunna fullgöra våra ömsesidiga åtaganden. Den behandling som baseras på samtycke har du möjlighet att själv återkalla via ditt konto.</p>
            <p>Om du inte har ett konto hos oss eller vill begära åtgärder som inte går att genomföra via ditt konto är du välkommen att kontakta oss på gdpr@campingonline.se. Vi behandlar ärenden så snart som möjligt i den ordning de kommer in. Om du inte har ett konto eller inte kontaktar oss via den e-postadress som angivits vid bokning kan vi behöva verifiera din identitet för att säkerställa att inga uppgifter lämnas ut till obehörig part.</p>
            <p>När uppgifterna inte längre är nödvändiga för angivet syfte kan du begära borttagning. För uppgifter som lagras baserat på samtycke kan du när som helst dra tillbaka ditt medgivande. Normalt lagrar vi dina uppgifter så länge du har en pågående kundrelation med campingplatsen för att underlätta för dig vid framtida bokningar.</p>
            <p>Din rätt till utdrag gäller oavsett om uppgifterna lagras baserat på ditt samtycke eller på annan rättslig grund.</p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item style="height: 450px; max-height: 450px; overflow-y: scroll;">
        <v-card flat>
          <v-card-text class="">
            <h1 class="pb-2">Cookie policy</h1>
            <h2 class="text-subtitle-2">Senaste uppdatering: 26 maj 2021</h2>

            <p>Kakor är små textfiler som placeras i din dator för att hålla reda på information om dig och som du har angett. Det finns fyra huvudsakliga kategorier av kakor. De nödvändiga kakorna behövs för att möjliggöra tjänster som du har efterfrågat. Övriga kakor sätt endast om du uttryckligen godkänner det.</p>
            <h2 class="text-subtitle-1">Nödvändiga</h2>
            <p>CampingOnline använder kakor för att tillhandahålla funktioner som inloggning och bokning. Dessa kakor är nödvändiga för att erbjuda den funktion som du har efterfrågat. Vi sätter förstapartskakor för att lagra data under bokningsprocessen.</p>
            <p>Kakor används också av vårt inloggningssystem Firebase för att kontrollera om det finns en aktiv session.</p>
            <p>Vårt lastbalanseringssystem Cloudflare sätter kakor för att kunna dirigera trafiken till rätt server på ett säkert och effektivt sätt.</p>
            <p>Google sätter kakor i samband med att man genomför en adressökning för att hålla reda på vilken adress som eftersökts.</p>
            <h2 class="text-subtitle-1">Funktionella</h2>
            <p>Facebook behöver sätta funktionella kakor för att kunna erbjuda chatt via messenger direkt på hemsidan.</p>
            <h2 class="text-subtitle-1">Spårning</h2>
            <p>Google Analytics finns aktiverat på sidan i syfte att se hur våra kunder interagerar med våra system för att identifiera möjliga förbättringar. Spårningen genomförs endast om du har samtyckt till detta genom att godkänna kakor i spårningssyfte. I övriga fall samlas bara anonymiserade och aggregerade data som inte kan spåras till en individuell användare.</p>
            <h2 class="text-subtitle-1">Marknadsföring</h2>
            <p>CampingOnline lagrar inga kakor för reklam och marknadsföringssyften.</p>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
  </v-dialog>
</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'TermsDialog',
  components: {

  },
  props: [],
  data: () => ({

  }),
  computed: {
    ...mapState(
      {
        showTermsDialog: state => state.showTermsDialog
      }
    ),
    show: {
      get: function () {
        return this.showTermsDialog
      },
      set: function (val) {
        this.$store.commit('SET_SHOW_TERMS_DIALOG', val)
      }
    }
  },
  methods: {

  },
  watch: {
    /* showTermsDialog: function() {
        if (this.showTermsDialog) {
          this.show = true;
        }
      } */
  },
  created () {

  }
}
</script>
