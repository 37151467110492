<template>
  <v-app :class="languageClass">
    <template v-if="isSelfCheckin">
      <v-main>
        <v-container style="height: 100%">
          <router-view />
        </v-container>
        <snackbars-component></snackbars-component>
      </v-main>
    </template>
    <template v-else>
      <web-header></web-header>
      <v-main>
        <v-container style="height: 100%">
          <locale-changer></locale-changer>
          <router-view />
        </v-container>
        <reservation-dialog></reservation-dialog>
        <snackbars-component></snackbars-component>
        <terms-dialog></terms-dialog>
      </v-main>
      <web-footer></web-footer>
    </template>
  </v-app>
</template>

<script>
import WebHeader from './layout/WebHeader.vue'
import WebFooter from './layout/WebFooter.vue'
import ReservationDialog from './components/ReservationDialog.vue'
import SnackbarsComponent from './components/SnackbarsComponent.vue'
import TermsDialog from './components/TermsDialog.vue'
import LocaleChanger from '@/components/LocaleChanger'

export default {
  name: 'App',
  components: {
    WebHeader,
    WebFooter,
    ReservationDialog,
    SnackbarsComponent,
    TermsDialog,
    LocaleChanger
  },
  data: () => ({}),
  computed: {
    isSelfCheckin: function () {
      return this.$store.state.isSelfCheckinPage
    },
    languageClass: function () {
      return `lang-${window.i18n.locale}`
    }
  },
  created () {
    /*
     * Koden nedan är till för att se till att höjden (100vh) på sidan blir rätt i mobil browsers som inkluderar höjden på browserns egna fält ovanför sidan i beräkningen, så att 100vh blir typ 50px högre än sidans faktiska höjd. T.ex. android chrome måste man scrolla ner för att se logga ut knappen som är längst ner i menyn
     * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
     **/
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
  }
}
</script>
