import axios from 'axios'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    accept: 'application/json',
    'ngrok-skip-browser-warning': 1
  }
})

const setToken = function (token) {
  if (token) {
    httpClient.defaults.headers.Authorization = 'Bearer ' + token
  } else {
    httpClient.defaults.headers.Authorization = ''
  }
}

const setLanguage = function (language) {
  httpClient.defaults.headers['x-user-language'] = language
}

/* console.log("setting axios interceptors");
httpClient.interceptors.response.use(
  response => {
    store.state.user.hasTimeoutError = false;
    return response;
  },
  error => {
    console.log("intercept error", error.response);
    if (error.response == undefined) {
      console.log("error.response == undefined");
      store.state.user.hasTimeoutError = true;
      store.commit('user/SET_AUTHENTICATED_STATUS', 'false');
    } else if (store.state.user.domain == '' || (error.response.status === 401 && error.response.data.message == 'Unauthenticated.')) {
      store.state.user.hasTimeoutError = false;
      store.commit('user/SET_AUTHENTICATED_STATUS', 'false');
      store.commit('user/REMOVE_USER');
      store.commit('user/REMOVE_TOKEN');
    }
    return Promise.reject(error);
  }
); */

export default { setToken, httpClient, setLanguage }
