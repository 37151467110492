import API from '@/services/API.js'
import router from '@/router'
import Vue from 'vue'

export const namespaced = true

export const state = {
  checkInDate: {
    date: null,
    readable: '',
    param: ''
  },
  checkOutDate: {
    date: null,
    readable: '',
    param: ''
  },
  dateValidation: {
    hasError: false,
    errorText: ''
  },
  adults: 2,
  children: 0,
  dogs: 0,
  cancellationPolicy: null,
  position: null,
  charges: {
    status: '',
    charges: [],
    checkIn: null,
    checkOut: null,
    categoryId: null,
    guestQuantity: null,
    dogsQuantity: null
  },
  fetchingCharges: {
    isFetching: false,
    checkIn: null,
    checkOut: null,
    categoryId: null,
    guestQuantity: null
  },
  reservation: { // Ev. ändringar ska också göras i REMOVE_RESERVATION mutation
    session: null,
    request: {
      isProcessing: false,
      check_in: null,
      check_out: null,
      position_id: null,
      category_id: null,
      occupancy: 0,
      dogs: 0
    },
    hold: null,
    is_available: false,
    receivedAt: null
  },
  isSavingBooking: false,
  saveBookingErrorMessage: '',
  confirmedBooking: null,
  selectedAddonOptions: [],
  triggerRedirect: 0,
  bookingInformation: null,
  isFetchingBookingInformation: false,
  hasConfirmationError: false
}

export const mutations = {
  SET_CHECK_IN_DATE (state, checkInDate) {
    state.checkInDate = checkInDate
    this.dispatch('booking/validateDates')
    this.dispatch('availability/fetchAvailability')
    this.dispatch('booking/fetchCharges')
    this.dispatch('booking/updateReservation')
  },
  SET_CHECK_OUT_DATE (state, checkOutDate) {
    state.checkOutDate = checkOutDate
    this.dispatch('booking/validateDates')
    this.dispatch('availability/fetchAvailability')
    this.dispatch('booking/fetchCharges')
    this.dispatch('booking/updateReservation')
  },
  SET_DATES (state, { checkInDate, checkOutDate }) {
    state.checkInDate = checkInDate
    state.checkOutDate = checkOutDate
    this.dispatch('booking/validateDates')
    this.dispatch('availability/fetchAvailability')
    this.dispatch('booking/fetchCharges')
    this.dispatch('booking/updateReservation')
  },
  SET_DATE_VALIDATION (state, val) {
    state.dateValidation = val
  },
  SET_ADULTS (state, adults) {
    state.adults = adults
    this.dispatch('availability/fetchAvailability')
    this.dispatch('booking/fetchCharges')
  },
  SET_CHILDREN (state, children) {
    state.children = children
    this.dispatch('availability/fetchAvailability')
    this.dispatch('booking/fetchCharges')
  },
  SET_DOGS (state, val) {
    state.dogs = val
    this.dispatch('availability/fetchAvailability')
    this.dispatch('booking/fetchCharges')
  },
  SET_CANCELLATION_POLICY (state, val) {
    if (val && val.charge) {
      const i = state.charges.charges.findIndex(c => !!c.isCancellationPolicy)
      if (i >= 0) {
        state.charges.charges.splice(i, 1)
      }
      val.charge.isCancellationPolicy = true
      state.charges.charges.push(val.charge)
    } else {
      const i = state.charges.charges.findIndex(c => !!c.isCancellationPolicy)
      if (i >= 0) {
        state.charges.charges.splice(i, 1)
      }
    }
    state.cancellationPolicy = val
  },
  SET_SELECTED_ADDON_OPTION (state, { addonId, optionId, charge }) {
    // ersätter / lägger till i selectedAddonOptions
    console.log('SET_SELECTED_ADDON_OPTION', addonId, optionId, charge)
    let i = state.selectedAddonOptions.findIndex(selectedAddonOption => selectedAddonOption.addonId === addonId)
    if (i >= 0) {
      state.selectedAddonOptions.splice(i, 1)
    }
    if (optionId) {
      state.selectedAddonOptions.push({
        addonId,
        optionId,
        charge
      })
    }

    // ersätter / lägger till charge
    i = state.charges.charges.findIndex(c => c.addonId === addonId)
    console.log('i', i)
    if (i >= 0) {
      state.charges.charges.splice(i, 1)
    }
    if (charge) {
      charge.isAddon = true
      charge.addonId = addonId
      state.charges.charges.push(charge)
    }
  },
  SET_POSITION (state, position) {
    if (state.position && state.position.category_id == position.category_id) {
      state.position = position
    } else {
      state.position = position
      this.dispatch('booking/fetchCharges')
    }

    // återställer valda tillval varje gång
    state.selectedAddonOptions = []
    state.charges.charges = state.charges.charges.filter(c => !c.isAddon)
    this.dispatch('availability/validatePosition')
  },
  SET_CHARGES (state, { data, checkIn, checkOut, categoryId, guestQuantity, dogsQuantity }) {
    console.log('SET_CHARGES', data.charges)
    state.charges.charges = data.charges
    state.charges.status = data.status
    state.charges.checkIn = checkIn
    state.charges.checkOut = checkOut
    state.charges.categoryId = categoryId
    state.charges.guestQuantity = guestQuantity
    state.charges.dogsQuantity = dogsQuantity
    if (state.cancellationPolicy && state.cancellationPolicy.charge) {
      state.cancellationPolicy.charge.isCancellationPolicy = true
      state.charges.charges.push(state.cancellationPolicy.charge)
    }
    state.selectedAddonOptions.forEach(selectedAddonOption => {
      const addon = state.position.category.addons.find(addon => addon.id === selectedAddonOption.addonId)
      if (addon) {
        const option = addon.options.find(option => option.id === selectedAddonOption.optionId)
        if (option && option.charge) {
          if (option.daily_price) {
            const nights = window.dayjs(checkOut).diff(window.dayjs(checkIn), 'days')
            option.charge.quantity = nights
            const total = nights * option.daily_price
            option.charge.textRight = `${total} kr SEK`
          }
          option.charge.isAddon = true
          option.charge.addonId = addon.id
          state.charges.charges.push(option.charge)
        }
      }
    })
  },
  SET_RESERVATION (state, data) {
    if (data.hold && data.hold.release_time) {
      data.hold.release_time = window.dayjs(data.hold.release_time)
    }
    state.reservation = data
  },
  REMOVE_RESERVATION (state) {
    state.reservation = {
      session: null,
      request: {
        isProcessing: false,
        check_in: null,
        check_out: null,
        position_id: null,
        category_id: null,
        occupancy: 0,
        dogs: 0
      },
      hold: null,
      is_available: false,
      receivedAt: null
    }
    state.cancellationPolicy = null
    state.selectedAddonOptions = []
  },
  SET_RESERVATION_IS_PROCESSING (state, isProcessing) {
    state.reservation.request.isProcessing = isProcessing
  },
  SET_IS_SAVING_BOOKING (state, val) {
    state.isSavingBooking = val
  },
  SET_CONFIRMED_BOOKING (state, val) {
    state.confirmedBooking = val
  },
  SET_SAVE_BOOKING_ERROR_MESSAGE (state, val) {
    state.saveBookingErrorMessage = val
  },
  TRIGGER_REDIRECT (state) {
    state.triggerRedirect++
  },
  SET_BOOKING_INFORMATION (state, val) {
    state.bookingInformation = val
    state.charges.charges = val.charges
  },
  SET_IS_FETCHING_BOOKING_INFORMATION (state, val) {
    state.isFetchingBookingInformation = val
  },
  SET_CONFIRMED_BOOKING_PAYMENT_INTENT (state, val) {
    Vue.set(state.confirmedBooking, 'paymentIntent', val)
  }
}

export const actions = {
  initialise ({ state, commit }, booking) {
    let reservation = null; let dateIn = null; let dateOut = null; let adults = null; let children = null; let dogs = null; let position = null; let confirmedBooking = null; let cancellationPolicy = false; let selectedAddonOptions = []

    if (booking) {
      booking = JSON.parse(booking)
      reservation = booking.reservation
      confirmedBooking = booking.confirmedBooking
      dateIn = window.dayjs(booking.checkInDate.date)
      dateOut = window.dayjs(booking.checkOutDate.date)
      adults = parseInt(booking.adults, 10)
      children = parseInt(booking.children, 10)
      dogs = parseInt(booking.dogs, 10)
      position = booking.position
      cancellationPolicy = booking.cancellationPolicy
      selectedAddonOptions = booking.selectedAddonOptions
    }

    if (reservation && reservation.hold && reservation.hold.release_time && (!reservation.request || !reservation.request.isProcessing)) {
      reservation.hold.release_time = window.dayjs(reservation.hold.release_time)
      if (reservation.hold.release_time.isAfter(window.dayjs().subtract(2, 'hours'))) {
        state.reservation = reservation
      }
    }

    if (confirmedBooking && confirmedBooking.created_at) {
      confirmedBooking.created_at = window.dayjs(confirmedBooking.created_at)
      if (confirmedBooking.created_at.isAfter(window.dayjs().subtract(1, 'day'))) {
        state.confirmedBooking = confirmedBooking
      }
    }

    if (dateIn && dateIn.isValid() && !dateIn.isBefore(window.dayjs().startOf('date')) && dateOut && dateOut.isValid() && dateOut.isAfter(dateIn)) {
      const checkInDate = {
        date: dateIn.clone(),
        readable: dateIn.format('ddd D MMM'),
        param: dateIn.format('YYYY-MM-DD')
      }
      state.checkInDate = checkInDate
      const checkOutDate = {
        date: dateOut.clone(),
        readable: dateOut.format('ddd D MMM'),
        param: dateOut.format('YYYY-MM-DD')
      }
      state.checkOutDate = checkOutDate
    }

    if (isNaN(adults) || adults <= 0) {
      adults = 2
    }
    state.adults = adults

    if (isNaN(children) || children <= 0) {
      children = 0
    }
    state.children = children

    if (isNaN(dogs) || dogs <= 0) {
      dogs = 0
    }
    state.dogs = dogs

    state.cancellationPolicy = cancellationPolicy

    commit('SET_POSITION', position)

    selectedAddonOptions.forEach(selectedAddonOption => {
      const addon = position.category.addons.find(addon => addon.id === selectedAddonOption.addonId)
      console.log('addon', addon, position.category.addons)
      if (addon) {
        const option = addon.options.find(option => option.id === selectedAddonOption.optionId)
        console.log('option', option)
        if (option) {
          commit('SET_SELECTED_ADDON_OPTION', {
            addonId: addon.id,
            optionId: option.id,
            charge: option.charge
          })
        }
      }
    })

    this.dispatch('booking/validateDates')
  },
  navigatingToCategoryPage ({ commit, rootState }, route) {
    console.log('navigatingToCategoryPage', route.from.name)
    if (route.from.name == 'SearchPage' || route.from.name == 'Index') {
      const payload = { checkInDate: rootState.search.checkInDate, checkOutDate: rootState.search.checkOutDate }
      commit('SET_DATES', payload)
      commit('SET_ADULTS', rootState.search.adults)
      commit('SET_CHILDREN', rootState.search.children)
      commit('SET_DOGS', rootState.search.dogs)
    }
  },
  fetchCharges ({ commit, state }) {
    if (!state.position) {
      return false
    }
    const checkIn = state.checkInDate.param
    const checkOut = state.checkOutDate.param
    const categoryId = state.position.category_id
    const guestQuantity = state.adults + state.children
    const dogsQuantity = state.dogs

    if (state.fetchingCharges.isFetching && state.fetchingCharges.checkIn == checkIn && state.fetchingCharges.checkOut == checkOut && state.fetchingCharges.categoryId == categoryId && state.fetchingCharges.guestQuantity == guestQuantity && state.fetchingCharges.dogsQuantity === dogsQuantity) {
      return false
    }
    state.fetchingCharges.isFetching = true
    state.fetchingCharges.checkIn = checkIn
    state.fetchingCharges.checkOut = checkOut
    state.fetchingCharges.categoryId = categoryId
    state.fetchingCharges.guestQuantity = guestQuantity
    state.fetchingCharges.dogsQuantity = dogsQuantity
    API.httpClient.get('booking/positioncharges', {
      params: {
        check_in: checkIn,
        check_out: checkOut,
        category_id: categoryId,
        guest_quantity: guestQuantity,
        dogs_quantity: dogsQuantity,
        electricity_selected: false // används bara från självincheckning just nu
      }
    }).then(({ data }) => {
      if (state.fetchingCharges.checkIn != checkIn || state.fetchingCharges.checkOut != checkOut || state.fetchingCharges.categoryId != categoryId || state.fetchingCharges.guestQuantity != guestQuantity || state.fetchingCharges.dogsQuantity !== dogsQuantity) {
        // kastar data, nyare data hämtas
      } else {
        commit('SET_CHARGES', { data, checkIn, checkOut, categoryId, guestQuantity, dogsQuantity })
        state.fetchingCharges.isFetching = false
      }
    }).catch((error) => {
      console.log('charges error response:', error, error.response)
      if (state.fetchingCharges.checkIn == checkIn && state.fetchingCharges.checkOut == checkOut && state.fetchingCharges.categoryId == categoryId && state.fetchingCharges.guestQuantity == guestQuantity && state.fetchingCharges.dogsQuantity == dogsQuantity) {
        state.fetchingCharges.isFetching = false
      }
    })
  },
  discardReservation ({ commit }) {
    commit('REMOVE_RESERVATION')
  },
  reservePosition ({ commit, state }) {
    if (state.reservation?.hold && state.reservation.hold.booking_number == state.confirmedBooking?.booking_number) {
      // Användaren har avbrutit en bekräftad reservation under betalning så vi får börja på en ny
      commit('REMOVE_RESERVATION')
    }
    state.reservation.request.isProcessing = true
    state.reservation.request.check_in = state.checkInDate.param
    state.reservation.request.check_out = state.checkOutDate.param
    state.reservation.request.position_id = state.position.id
    state.reservation.request.category_id = state.position.category_id
    state.reservation.request.occupancy = (state.adults + state.children)
    state.reservation.request.dogs = state.dogs
    return API.httpClient.get('booking/hold', {
      // Denna skickar allt som check_in parameter ibland, kanske post hade löst det
      params: {
        check_in: state.reservation.request.check_in,
        check_out: state.reservation.request.check_out,
        position_id: state.reservation.request.position_id,
        category_id: state.reservation.request.category_id,
        session: state.reservation.session,
        occupancy: state.reservation.request.occupancy,
        dogs: state.reservation.request.dogs
      }
    }).then(({ data }) => {
      if (
        window.dayjs(data.hold.check_in).isSame(state.checkInDate.date) &&
        window.dayjs(data.hold.check_out).isSame(state.checkOutDate.date) &&
        (state.position.id == 0 || data.hold.position_id == state.position.id) && /* tilldelas en plats om inte position_id */
        data.hold.category_id == state.position.category_id
      ) {
        commit('SET_RESERVATION', {
          is_available: data.is_available,
          session: data.session,
          request: state.reservation.request,
          hold: data.hold,
          receivedAt: window.dayjs()
        })
      }
    }).catch((error) => {
      console.log('reservePosition error response:', error, error.response)
      // TODO: ge svar och redirecta inte
    }).finally(() => {
      commit('SET_RESERVATION_IS_PROCESSING', false)
    })
  },
  updateReservation ({ rootState, state, dispatch }) {
    if (rootState.currentPageName !== 'CategoryPage' && rootState.currentPageName !== 'SearchPage' && rootState.currentPageName !== 'Index') {
      if (state.reservation.session) {
        dispatch('reservePosition')
      }
    }
  },
  validateDates ({ state, commit }) {
    if (!state.checkInDate.date || !state.checkInDate.date.isValid()) {
      commit('SET_DATE_VALIDATION', {
        hasError: true,
        errorText: 'Välj ett incheckningsdatum'
      })
    } else if (!state.checkOutDate.date || !state.checkOutDate.date.isValid()) {
      commit('SET_DATE_VALIDATION', {
        hasError: true,
        errorText: 'Välj ett utcheckningsdatum'
      })
    } else if (state.checkInDate.date.isBefore(window.dayjs().startOf('day'))) {
      commit('SET_DATE_VALIDATION', {
        hasError: true,
        errorText: 'Datumet har passerat'
      })
    } else if (state.checkOutDate.date.isSameOrBefore(state.checkInDate.date)) {
      commit('SET_DATE_VALIDATION', {
        hasError: true,
        errorText: 'Välj ett senare utcheckningsdatum'
      })
    } else {
      commit('SET_DATE_VALIDATION', {
        hasError: false,
        errorText: ''
      })
    }
  },
  saveBooking ({ state, commit, dispatch }, { paymentMethod, awaitConfirmation, isRetry }) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_SAVING_BOOKING', true)
      if (state.reservation?.hold?.booking_number === state.confirmedBooking?.booking_number && state.confirmedBooking?.booking_number != null) {
        if (paymentMethod === 'stripe') {
          resolve()
          return
        }
        // bytt från stripe till betala senare
        // denna bokning ligger färdig och väntar på stripe webhook men vi bekräftar den med annan betalmetod
        API.httpClient.post('booking/confirm-payment', {
          session: state.reservation.session,
          payment_method: paymentMethod
        })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('SET_CONFIRMED_BOOKING', data.booking)
              resolve()
            } else {
              commit('SET_SAVE_BOOKING_ERROR_MESSAGE', window.i18n.t('booking.booking_not_confirmed'))
              commit('REMOVE_RESERVATION')
              commit('TRIGGER_REDIRECT')
              reject()
            }
          })
          .catch((error) => {
            console.log('confirmPayment error', error)
            commit('SET_SAVE_BOOKING_ERROR_MESSAGE', window.i18n.t('booking.booking_not_confirmed'))
            commit('REMOVE_RESERVATION')
            commit('TRIGGER_REDIRECT')
            reject()
          })
          .finally(() => {
            commit('SET_IS_SAVING_BOOKING', false)
          })
        return
      }

      API.httpClient.post('booking/create', {
        session: state.reservation.session,
        adults: state.adults,
        children: state.children,
        dogs: state.dogs,
        payment_method: paymentMethod,
        cancellation_policy: state.cancellationPolicy,
        charges: state.charges.charges,
        position_id: state.position.id,
        category_id: state.position.category_id,
        selected_addon_options: state.selectedAddonOptions,
        await_confirmation: !!awaitConfirmation,
        electricity_selected: false // används bara från självincheckning just nu
      })
        .then(({ data }) => {
          if (data.status === 'success') {
            commit('SET_CONFIRMED_BOOKING', data.booking)
            if (!awaitConfirmation) {
              // Låter den fortsätta ladda hela vägen tills Stripes redirect om har awaitConfirmation
              commit('SET_IS_SAVING_BOOKING', false)
            }
            resolve()
          } else if (data.status === 'hold_not_found' && !isRetry) {
            commit('SET_SAVE_BOOKING_ERROR_MESSAGE', window.i18n.t('booking.booking_not_confirmed'))

            setTimeout(() => {
              dispatch('reservePosition').then(() => {
                setTimeout(() => {
                  dispatch('saveBooking', {
                    isRetry: true,
                    paymentMethod,
                    awaitConfirmation
                  })
                }, 1000)
              })
            }, 500)
          } else {
            commit('SET_SAVE_BOOKING_ERROR_MESSAGE', window.i18n.t('booking.booking_not_confirmed'))
            commit('REMOVE_RESERVATION')
            commit('TRIGGER_REDIRECT')
            reject()
            commit('SET_IS_SAVING_BOOKING', false)
          }
        })
        .catch((error) => {
          console.log('saveBooking error', error)
          commit('SET_SAVE_BOOKING_ERROR_MESSAGE', window.i18n.t('booking.booking_not_confirmed'))
          commit('REMOVE_RESERVATION')
          commit('TRIGGER_REDIRECT')
          reject()
          commit('SET_IS_SAVING_BOOKING', false)
        })
    })
  },
  getBookingInformation ({ commit, dispatch, state }) {
    const confirmedBookingSession = state.confirmedBooking.session_key
    commit('SET_IS_FETCHING_BOOKING_INFORMATION', true)
    return API.httpClient.get('booking/get-booking-information', {
      params: {
        session: confirmedBookingSession
      }
    })
      .then(({ data }) => {
        if (data.status === 'success') {
          dispatch('discardReservation')
          commit('SET_BOOKING_INFORMATION', data)
        } else if (data.status === 'booking_not_confirmed') {
          router.push({ name: 'PaymentPage' })
        } else {
          dispatch('discardReservation')
          commit('SET_CONFIRMED_BOOKING', null)
          commit('REMOVE_RESERVATION')
          router.push({ name: 'SearchPage' })
        }
      })
      .catch((error) => {
        console.log('getBookingInformation error:', error)
        dispatch('discardReservation')
        commit('SET_CONFIRMED_BOOKING', null)
        commit('REMOVE_RESERVATION')
        router.push({ name: 'SearchPage' })
      })
      .finally(() => {
        commit('SET_IS_FETCHING_BOOKING_INFORMATION', false)
      })
  }
}

export const getters = {
  charges (state) {
    if (state.charges && state.charges.status === 'success' && state.charges.charges) {
      const arr = []
      state.charges.charges.forEach(charge => {
        const c = charge
        if (!c.textLeft) {
          c.textLeft = c.name
          if (c.unit && c.unit !== '') {
            c.textLeft += `, ${c.quantity} ${c.unit}`
          }
        }
        if (!c.textRight) {
          c.textRight = ''
          if (c.price || c.price === 0) {
            c.textRight += Math.round(c.quantity * c.price)
            if (c.currency) {
              c.textRight += ' ' + c.currency
            }
          }
        }
        arr.push(c)
      })
      return arr
    }
    return []
  },
  totalPrice (state) {
    if (state.charges && state.charges.status === 'success' && state.charges.charges) {
      let sum = 0
      state.charges.charges.forEach(c => { sum += (c.quantity * c.price) })
      return Math.round(sum) * 100
    }
    return null
  },
  totalPriceFormatted (state, getters) {
    if (getters.totalPrice === null) {
      return ''
    }
    return Math.round(getters.totalPrice / 100) + ' kr SEK'
  },
  hasUnpaidBooking (state) {
    return !!state.reservation?.hold && state.reservation?.hold?.booking_number === state.confirmedBooking?.booking_number
  }
}
