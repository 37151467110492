var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"snackbars-component"},[_c('v-snackbar',{attrs:{"color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showLoggedIn = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.showLoggedIn),callback:function ($$v) {_vm.showLoggedIn=$$v},expression:"showLoggedIn"}},[_vm._v(" Du är nu inloggad! ")]),_c('v-snackbar',{attrs:{"color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showLoggedOut = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.showLoggedOut),callback:function ($$v) {_vm.showLoggedOut=$$v},expression:"showLoggedOut"}},[_vm._v(" Du är nu utloggad! ")]),_c('v-snackbar',{attrs:{"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showSaveBookingError = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.showSaveBookingError),callback:function ($$v) {_vm.showSaveBookingError=$$v},expression:"showSaveBookingError"}},[_vm._v(" "+_vm._s(_vm.saveBookingErrorMessage)+" ")]),_c('v-snackbar',{attrs:{"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showSaveCheckinError = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.showSaveCheckinError),callback:function ($$v) {_vm.showSaveCheckinError=$$v},expression:"showSaveCheckinError"}},[_vm._v(" "+_vm._s(_vm.saveCheckinErrorMessage)+" ")]),_c('v-snackbar',{attrs:{"color":"info"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showAdultsChangedMessage = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.showAdultsChangedMessage),callback:function ($$v) {_vm.showAdultsChangedMessage=$$v},expression:"showAdultsChangedMessage"}},[_vm._v(" "+_vm._s(_vm.adultsChangedMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }