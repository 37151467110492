import API from '@/services/API.js'

export const namespaced = true

export const state = {
  countries: [],
  isFetchingCountries: false
}

export const mutations = {
  SET_COUNTRIES (state, countries) {
    state.countries = countries
  }
}

export const actions = {
  fetchCountries ({ commit, state }) {
    state.isFetchingCountries = true
    return API.httpClient.get('countries')
      .then(({ data }) => {
        commit('SET_COUNTRIES', data)
        state.isFetchingCountries = false
      })
  },
  fetchCountriesOnce ({ state }) {
    if (!state.isFetchingCountries && state.countries.length === 0) {
      return this.dispatch('country/fetchCountries')
    }
    return new Promise((resolve) => {
      resolve(true)
    })
  }
}

export const getters = {
  defaultCountry: function (state) {
    if (state.countries.length === 0) {
      return null
    }
    return state.countries.find(c => c.id === 752) // 752 = Sverige
  }
}
